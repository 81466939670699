.modal-item-text{
    list-style-type: none;
    font-weight: bold;
    background-color: navy;
    color: white;
    border: solid 2px white;
    border-radius: 9px;
    width: max-content;
    cursor: pointer;
    /* margin-top: 21px; */
    margin-bottom: 27px;
    box-shadow: 0px 0px 9px 3px rgba(0, 0, 0, 0.34);
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-left: 7px;
    padding-right: 7px;
    font-family:  'Circular', 'BlinkMacSystemFont', 'Roboto', 'Helvetica Neue', sans-serif;

}

.modal-item-text:active{
    transform: scale(0.98);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
}
