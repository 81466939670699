.hidden {
    display: none
}
.logo-text{
    /* transform: rotate(180deg); */
    color: white;
    border: 3px solid white;
    border-radius: 21px;
    font-size: 13px;
    box-shadow: 0px 0px 21px 1px white;
 }


 .logo-left{
    transform: rotate(90deg);
    font-size: 21px;
    color: white;
 }


 .logo-right{
    transform: rotate(-90deg);
    font-size: 21px;
    color: white;
 }


 .nav-bar {
    width: 100%;
    position: sticky;
    box-sizing: border-box;
    top: 0px;
    background-color: navy;
    border-bottom: 5px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    /* padding: 3px 5px; */
    z-index:1;
    border-radius: 0px 0px 11px 11px;
 }


 .nav-bar-contents {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: center;
    padding: 3px 7px;
 }


 .nav-bar-left {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
 }

 .nav-bar-right {
   display: flex;
   align-items: center;
   gap: 12px;
 }

 .create-new-spot{
   font-family:  'Circular', 'BlinkMacSystemFont', 'Roboto', 'Helvetica Neue', sans-serif;
   text-decoration: none;
   background-color: navy;
   /* width: fit-content; */
   padding: 13px;
   border: white solid;
   border-radius: 13px;
   color: white;
   font-weight: bold;
   display: flex;
   /* flex-direction: row; */
   box-sizing: border-box;
   margin: 13px;
   box-shadow: 0px 0px 21px 1px white;
 }

 .profile-button{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
    margin: 10px;
 }
