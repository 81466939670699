.manage-create-spot-container{
    padding-left: 53px;
}


.no-spots-heading{
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    justify-content: left;
    gap: 31px;
    padding-top: 33px;
    justify-content: left;
    color: navy;

}

.manage-spots-heading{
    /* font-family:Verdana, Geneva, Tahoma, sans-serif;
    justify-content: left;
    display: grid;
    grid-template-columns: repeat(auto-fill, 233px);
    gap: 31px;
    padding-top: 33px;
    justify-content: center;
    max-width: 1221px;
    margin: auto;
    color: navy; */
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-size: 25px;
    justify-content: left;
    display: grid;
    grid-template-columns: repeat(auto-fill, 313px);
    color: navy;
    gap: 31px;
    padding-top: 23px;
    justify-content: center;
    max-width: 1473px;
    margin: auto;

}

.manage-create-spot{
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    color: white;
    display: flex;
    padding: 13px;
    justify-content: center;
    font-family:  'Circular', 'BlinkMacSystemFont', 'Roboto', 'Helvetica Neue', sans-serif;
    text-decoration: none;
    background-color: red;
    font-weight: bold;
    border: 2px solid white;
    width: 145px;
    border-radius: 13px;
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    border-radius: 13px;
}

.manage-create-spot:active{
    transform: scale(0.98);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);
    border-radius: 13px;
}

.update-delete-spot{
    display: flex;
    padding-top: 17px;
    padding-left: 5px;
    margin-bottom: 27px;
    gap: 13px;

}

/* .update-spot-button{
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    background-color: white;
    color: navy;

    border: solid 2px navy;
    border-radius: 7px;
    cursor: pointer;


} */

.update-spot-button{
    list-style-type: none;
    font-weight: bold;
    background-color: navy;
    color: white;
    border: solid 2px white;
    border-radius: 9px;
    width: max-content;
    cursor: pointer;
    /* margin-top: 21px; */
    margin-bottom: 27px;
    box-shadow: 0px 0px 9px 3px rgba(0, 0, 0, 0.34);
    height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-left: 7px;
    padding-right: 7px;
    text-decoration: none;
    font-family:  'Circular', 'BlinkMacSystemFont', 'Roboto', 'Helvetica Neue', sans-serif;
}

/* .delete-spot-button{
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-weight: bold;
    background-color: white;
    color: navy;

    border: solid 2px navy;
    border-radius: 7px;
    list-style-type: none;
    cursor: pointer;


} */

.update-spot-button:active{
    transform: scale(0.98);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);

}

.delete-spot-button:active{
    transform: scale(0.98);
    box-shadow: 3px 2px 22px 1px rgba(0, 0, 0, 0.24);

}
