.signup-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    border: solid 5px white;
    padding: 10px;
    overflow-y: auto;
    border-radius: 13px;
}

.signup-title {
    font-family:  'Circular', 'BlinkMacSystemFont', 'Roboto', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    text-align: center;
    width: 100%;
    border-bottom: 3px solid navy;
    font-size: 20px;
    /* padding-bottom: 10px; */
    color: navy;
}

.signup-errors{
    font-family:  'Circular', 'BlinkMacSystemFont', 'Roboto', 'Helvetica Neue', sans-serif;
    font-weight: bolder;
    color: orange;
    text-align: left;
    height: 100%;
    font-style: oblique;
    font-size:xx-small;
    margin-top: 3px;
    margin-left: 3px;
}

.signup-input {
    font-family:  'Circular', 'BlinkMacSystemFont', 'Roboto', 'Helvetica Neue', sans-serif;
    font-weight: bolder;
    color: navy;
    justify-content: center;
    margin-top: 7px;
    height: 100%;
    text-align: left;
    padding-left: 33px;
    padding-right: 33px;
}

.signup-input-box{
    margin-top: 3px;
    width: 225px;
    border: solid 3px navy;
    border-radius: 5px;
    justify-content: center;
    height: 23px;
    padding: 5px;
}

.signup-submit-button{
    font-family: 'Circular', 'BlinkMacSystemFont', 'Roboto', 'Helvetica Neue', sans-serif;
    font-size: 17px;
    font-weight: bold;
    color: white;
    background-color:red;
    border: 3px solid white;
    cursor: pointer;
    padding: 3px;
    border-radius: 10px;
    /* margin-top: 15px; */
    width: 243px;
    height: 53px;
}

.signup-disabled{
    font-family: 'Circular', 'BlinkMacSystemFont', 'Roboto', 'Helvetica Neue', sans-serif;
    font-size: 17px;
    font-weight: bold;
    color: lightgray;
    background-color:white;
    border: 3px solid lightgray;
    cursor: not-allowed;
    /* padding: 3px; */
    border-radius: 10px;
    /* margin-top: 15px; */
    width: 233px;
    height: 33px;

}

.signup-button-container{
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}
