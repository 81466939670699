#modal-content {
    border-radius: 15px;
    border: solid navy 5px;
    box-shadow: 0px 0px 21px 1px white;
    justify-content: center;
}

.login-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: fit-content;
    padding: 17px;
}

.login-title {
    font-family:  'Circular', 'BlinkMacSystemFont', 'Roboto', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    text-align: center;
    width: 100%;
    border-bottom: 3px solid navy;
    font-size: 20px;
    padding-bottom: 10px;
    color: navy;
}

.login-error-message {
    font-family:  'Circular', 'BlinkMacSystemFont', 'Roboto', 'Helvetica Neue', sans-serif;
    font-weight: bold;
    text-align: center;
    width: 100%;
    font-size: 13px;
    color: orange;
    border-bottom: 3px solid navy;
    padding-bottom: 10px;
    padding-top: 10px;
}

.login-errors{
    font-family:  'Circular', 'BlinkMacSystemFont', 'Roboto', 'Helvetica Neue', sans-serif;
    font-weight: bolder;
    color: orange;
    justify-content: center;
    height: 100%;
    font-style: oblique;
    font-size:xx-small;
    margin-top: 3px;
    margin-left: 3px;
    text-align: left;
}

.login-input {
    font-family:  'Circular', 'BlinkMacSystemFont', 'Roboto', 'Helvetica Neue', sans-serif;
    font-weight: bolder;
    color: navy;
    justify-content: center;
    margin-top: 23px;
    height: 100%;
    text-align: center;
    padding-left: 33px;
    padding-right: 33px;
}

.input-box{
    margin-top: 7px;
    /* width: 181px; */
    border: solid 3px navy;
    border-radius: 5px;
    justify-content: center;
    /* height: 33px; */
    font-size: large;
    padding: 11px;
}

.login-submit-button{
    font-family: 'Circular', 'BlinkMacSystemFont', 'Roboto', 'Helvetica Neue', sans-serif;
    font-size: 23px;
    font-weight: bold;
    color: white;
    background-color: red;
    border: 3px solid white;
    cursor: pointer;
    padding: 3px;
    border-radius: 10px;
    margin-top: 15px;
    width: 191px;
    height: 43px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.demo-button-container{
    display: flex;
    margin-top: 23px;
    width: 191px;
    align-items: center;
    justify-content: center;
}

.demo-user-login{
    font-family: 'Circular', 'BlinkMacSystemFont', 'Roboto', 'Helvetica Neue', sans-serif;
    font-size: 17px;
    font-weight: bold;
    color: white;
    background-color: navy;
    cursor: pointer;
    padding: 11px;
    border-radius: 10px;
    text-decoration: none;
    align-items: center;
    width: 173px;
    text-align: center;
}

.login-disabled{
    font-family: 'Circular', 'BlinkMacSystemFont', 'Roboto', 'Helvetica Neue', sans-serif;
    font-size: 14px;
    font-weight: bold;
    color: lightgray;
    background-color:white;
    border: 3px solid lightgray;
    cursor: not-allowed;
    padding: 3px;
    border-radius: 10px;
    margin-top: 15px;
    width: 181px;
    height: 43px;
}

.login-button-container{
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.login-logo-text{
    /* transform: rotate(180deg); */
    background-color: navy;
    color: white;
    padding: 3px;
    border: 3px solid navy;
    border-radius: 21px;
    font-size: 7px;
    width: 99px;
    text-align: center;
    /* font-size: 17px; */
 }

 .login-logo-left{
    transform: rotate(90deg);
    font-size: 13px;
    color: navy;
 }


 .login-logo-right{
    transform: rotate(-90deg);
    font-size: 13px;
    color: navy;
 }
